import React, { useState, useEffect, useRef } from "react";
import { Form, Card, Button, Container, Modal } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

function Admin() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const containerRef = useRef(null);
  const fixedChildRef = useRef(null);
  const [addProduct, setAddProduct] = useState(false);
  const [editHederText, setEditHeaderText] = useState(false);
  const [headerText, setHeaderText] = useState("");

  const updateFixedChildWidth = () => {
    if (containerRef.current && fixedChildRef.current) {
      fixedChildRef.current.style.width = `${containerRef.current.offsetWidth}px`;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    validateLogin();
    updateFixedChildWidth();
    fetchProducts();
    fetchHeaderText();
    window.addEventListener("resize", updateFixedChildWidth);
  }, []);

  const validateLogin = async () => {
    try {
      const response = await fetch("https://shop.gallowayhillbillies.org/api/validate-login", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "session-id": localStorage.getItem("sessionId"),
        },
      });
      if (response.ok) {
        // pass
      } else {
        window.location.href = "/admin/login";
      }
    } catch (error) {
      console.error("Error validating login:", error);
      setTimeout(() => {
        updateFixedChildWidth();
      }, 1);
      setError("Error validating login" + error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch("https://shop.gallowayhillbillies.org/api/load-items");
      const data = await response.json();

      const formattedData = data.map((product) => ({
        ...product,
        sizes: JSON.parse(product.sizes),
      }));

      setProducts(formattedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setError("Error fetching products");
      setTimeout(() => {
        updateFixedChildWidth();
      }, 1);
      setIsLoading(false);
    }
  };

  const [newProductid, setNewProductid] = useState("");
  const [newProductTitle, setNewProductTitle] = useState("");
  const [newProductPrice, setNewProductPrice] = useState("");
  const [newProductSizes, setNewProductSizes] = useState([]);
  const [newProductImage, setNewProductImage] = useState(null);
  const [newProductImage2, setNewProductImage2] = useState(null);

  const editProductImage = async (id, imgtype) => {
    // Create an invisible file input on the fly
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".jpg, .jpeg, .png, .gif, .webp, .jfif"; // Limit to only image files
    fileInput.style.display = "none"; // Make it invisible
    document.body.appendChild(fileInput); // Append it to the body

    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (!file) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      if (imgtype === 1) {
        formData.append("image", file);
      } else if (imgtype === 2) {
        formData.append("image2", file);
      }

      try {
        const response = await fetch(`https://shop.gallowayhillbillies.org/api/update-images/${id}`, {
          method: "PUT",
          headers: {
            "session-id": localStorage.getItem("sessionId"),
          },
          body: formData,
        });

        if (response.ok) {
          console.log("Image updated successfully");
          fetchProducts();
        } else {
          console.error("Error updating the image");
          // Handle error
        }
      } catch (error) {
        console.error("Error updating the image:", error);
        // Handle error
      }
      document.body.removeChild(fileInput);
    };
    fileInput.click();
  };

  const handleAddProduct = async () => {
    const formData = new FormData();
    formData.append("id", newProductid);
    formData.append("title", newProductTitle);
    formData.append("price", newProductPrice);
    formData.append("sizes", JSON.stringify(newProductSizes));
    formData.append("image", newProductImage); // Assuming newProductImage is a File object
    if (newProductImage2) {
      formData.append("image2", newProductImage2); // Assuming newProductImage2 is a File object
    }

    try {
      const response = await fetch("https://shop.gallowayhillbillies.org/api/add-product", {
        method: "POST",
        headers: {
          "session-id": localStorage.getItem("sessionId"),
        },
        body: formData,
      });

      if (response.ok) {
        fetchProducts();
        setAddProduct(false);
      } else {
        console.error("Failed to add product:", response.status);
        setError("Failed to add product " + response.status + " " + response.statusText);
        setTimeout(() => {
          updateFixedChildWidth();
        }, 10);
      }
    } catch (error) {
      console.error("Error adding product:", error);
      setError("Error adding product" + error);
      setTimeout(() => {
        updateFixedChildWidth();
      }, 1);
    }
  };

  const handleEditProduct = (productId) => {
    // Implement logic to edit a product with the given productId
    const productToEdit = products.find((product) => product.id === productId);
    if (productToEdit) {
      setEditProductId(productId);
      setEditProductTitle(productToEdit.title);
      setEditProductPrice(productToEdit.price);
      setEditProductSizes(productToEdit.sizes);
    }
  };

  const removeImage = async (productId) => {
    try {
      const response = await fetch(`https://shop.gallowayhillbillies.org/api/remove-image/${productId}`, {
        method: "DELETE",
        headers: {
          "session-id": localStorage.getItem("sessionId"),
        },
      });

      if (response.ok) {
        console.log("Image removed successfully");
        fetchProducts();
      } else {
        console.error("Error removing the image");
        // Handle error
      }
    } catch (error) {
      console.error("Error removing the image:", error);
      // Handle error
    }
  };

  const fetchHeaderText = async () => {
    try {
        const response = await fetch("https://shop.gallowayhillbillies.org/api/get-header-text");
        const data = await response.json();
        console.log(data);
        setHeaderText(data.header_text);
    } catch (error) {
        console.error("Error fetching header text:", error);
    }
}

  const handleHeaderTextChange = (event) => {
    setHeaderText(event.target.value);
};

  const updateHeaderText = async () => {
    try {
      const response = await fetch("https://shop.gallowayhillbillies.org/api/update-header-text", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "session-id": localStorage.getItem("sessionId"),
        },
        body: JSON.stringify({ headerText: headerText }),
      });

      if (response.ok) {
        console.log("Header text updated successfully");
        setEditHeaderText(false);
      } else {
        console.error("Error updating the header text");
        setError("Error updating header text");
        setIsLoading(false);
        setTimeout(() => {
          updateFixedChildWidth();
        }, 1);
        // Handle error
      }
    } catch (error) {
      console.error("Error updating header text:", error);
    setError("Error updating header text" + error);
      setIsLoading(false);
      setTimeout(() => {
        updateFixedChildWidth();
      }, 1);
    }
  };

  const handleRemoveProduct = async (productId) => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://shop.gallowayhillbillies.org/api/remove-product/${productId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "session-id": localStorage.getItem("sessionId"),
        },
      });

      if (response.ok) {
        setIsLoading(false);
        fetchProducts();
      } else {
        console.error("Failed to remove product:", response.status);
        setError("Failed to remove product " + response.status + " " + response.statusText);
        setIsLoading(false);
        setTimeout(() => {
          updateFixedChildWidth();
        }, 1);
      }
    } catch (error) {
      console.error("Error removing product:", error);
      setError("Error removing product" + error);
      setIsLoading(false);
      setTimeout(() => {
        updateFixedChildWidth();
      }, 1);
    }
  };

  const [editProductId, setEditProductId] = useState(null);
  const [editProductTitle, setEditProductTitle] = useState("");
  const [editProductPrice, setEditProductPrice] = useState("");
  const [editProductSizes, setEditProductSizes] = useState([]);

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "title") {
      setEditProductTitle(value);
    } else if (name === "price") {
      setEditProductPrice(value);
    } else if (name === "sizes") {
      setEditProductSizes(value.split(","));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "id") {
      setNewProductid(value);
    } else if (name === "title") {
      setNewProductTitle(value);
    } else if (name === "price") {
      setNewProductPrice(value);
    } else if (name === "sizes") {
      setNewProductSizes(value.split(","));
    } else if (name === "image") {
      setNewProductImage(event.target.files[0]);
    } else if (name === "image2") {
      setNewProductImage2(event.target.files[0]);
    }
  };

  const handleEditProductSubmit = async () => {
    // Implement logic to submit the edited product
    const editedProduct = {
      id: editProductId,
      title: editProductTitle,
      price: editProductPrice,
      sizes: editProductSizes,
    };

    try {
      const response = await fetch(`https://shop.gallowayhillbillies.org/api/update-product/${editProductId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "session-id": localStorage.getItem("sessionId"),
        },
        body: JSON.stringify(editedProduct),
      });

      if (response.ok) {
        // Product updated successfully
        // Reset the edit state
        setEditProductId(null);
        setEditProductTitle("");
        setEditProductPrice("");
        setEditProductSizes([]);
        fetchProducts();
      } else {
        console.error("Failed to update product:", response.status);
        setError("Failed to update product " + response.status + " " + response.statusText);
        setTimeout(() => {
          updateFixedChildWidth();
        }, 1);
      }
    } catch (error) {
      console.error("Error updating product:", error);
      setError("Error updating product" + error);
      setTimeout(() => {
        updateFixedChildWidth();
      }, 1);
    }
  };

return (
    <Container ref={containerRef}>
        {error && (
            <Alert
                ref={fixedChildRef}
                variant="danger"
                onClose={() => setError(false)}
                style={{ width: "25rem", textAlign: "center" }}
                dismissible
            >
                <Alert.Heading>Error</Alert.Heading>
                <p>{error}</p>
            </Alert>
        )}
        <Modal show={addProduct} onHide={() => setAddProduct(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control
                    type="text"
                    name="id"
                    value={newProductid}
                    onChange={handleInputChange}
                    placeholder="ID (stripe API ID)"
                />
                <p></p>
                <Form.Control
                    type="text"
                    name="title"
                    value={newProductTitle}
                    onChange={handleInputChange}
                    placeholder="Title"
                />
                <p></p>
                <Form.Control
                    type="text"
                    name="price"
                    value={newProductPrice}
                    onChange={handleInputChange}
                    placeholder="Price"
                />
                <p></p>
                <Form.Control
                    type="text"
                    name="sizes"
                    value={newProductSizes}
                    onChange={handleInputChange}
                    placeholder="Sizes (comma separated)"
                />
                <p></p>
                Image 1:
                <Form.Control
                    type="file"
                    name="image"
                    onChange={handleInputChange}
                    placeholder="Image"
                    multiple
                    accept=".jpg, .jpeg, .png, .gif, .webp, .jfif"
                />
                <p></p>
                Image 2: (Optional)
                <Form.Control
                    type="file"
                    name="image2"
                    onChange={handleInputChange}
                    placeholder="Image"
                    multiple
                    accept=".jpg, .jpeg, .png, .gif, .webp, .jfif"
                />
                <p></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setAddProduct(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleAddProduct}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={editHederText} onHide={() => setEditHeaderText(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Header Text</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Supports html embeding</p>
                <Form.Control
                    as="textarea"
                    name="headerText"
                    value={headerText}
                    onChange={handleHeaderTextChange}
                    placeholder="Header Text"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setEditHeaderText(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={updateHeaderText}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

        <h1 align="center">Welcome</h1>
        <p></p>
        <Button className="editHeaderText" onClick={() => setEditHeaderText(true)}>
            Edit Header Text
        </Button>
        <p></p>
        <h3 align="center">
            The ID for each product is a stripe ID without this ID the product{" "}
            <strong style={{ color: "red", textDecoration: "underline" }}>WILL NOT WORK!!!</strong>
        </h3>
        <h5 align="center">
            Please vist{" "}
            <a
                target="_blank"
                rel="noreferrer"
                href="https://dashboard.stripe.com/products?active=true&create=product&source=product_list"
            >
                The Sripe dashboard
            </a>{" "}
            to create a product. Once you have done that click On the product and look for the API ID
        </h5>
        <h5 align="center">Please make sure to add the sizes in the correct format</h5>
        <h5 align="center">Example: S, M , L, XL</h5>

        <div
            align="center"
            style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
            }}
        >
            {products.map((product) => (
                <Card key={product.id} style={{ width: "25rem" }}>
                    <p></p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <p></p>
                        <div className="image-container" onClick={() => editProductImage(product.id, 1)}>
                            <Card.Img variant="top" src={`api/${product.image}`} style={{ width: "8rem" }} />
                            <div className="img-overlay">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-pencil-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10ZM11.207 2.5 4 9.707V10h.293L11.5 3.793 11.207 2.5ZM10.5 3.086 9.914 3.672 11.328 5.086l.586-.586L10.5 3.086ZM2 13V11.5l.086-.086 2.372 2.372-.086.214-2.372-.5Z" />
                                </svg>
                            </div>
                        </div>
                        <>
                            <div className="image-container  overlay-both"></div>
                <div className="image-container  overlay-both">
                  <Card.Img variant="top" src={`api/${product.image2}`} style={{ width: "8rem" }} />
                  <div className="overlay-top-half" onClick={() => editProductImage(product.id, 2)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pencil-halffill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10ZM11.207 2.5 4 9.707V10h.293L11.5 3.793 11.207 2.5ZM10.5 3.086 9.914 3.672 11.328 5.086l.586-.586L10.5 3.086ZM2 13V11.5l.086-.086 2.372 2.372-.086.214-2.372-.5Z" />
                    </svg>
                  </div>
                  {product.image2 && product.image2.length > 0 && (
                    <div className="overlay-bottom-half" onClick={() => removeImage(product.id)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pencil-halffill"
                        viewBox="0 0 512 512"
                      >
                        <path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
                      </svg>
                    </div>
                  )}
                </div>
              </>
            </div>
            <Card.Body>
              {editProductId === product.id ? (
                <>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={editProductTitle}
                    onChange={handleEditInputChange}
                  />
                  <Form.Control
                    type="text"
                    name="price"
                    placeholder="Price"
                    value={editProductPrice}
                    onChange={handleEditInputChange}
                  />
                  <Form.Control
                    type="text"
                    name="sizes"
                    placeholder="Sizes (comma separated)"
                    value={editProductSizes.join(",")}
                    onChange={handleEditInputChange}
                  />
                  <Button variant="primary" onClick={handleEditProductSubmit}>
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      // Reset the edit state
                      setEditProductId(null);
                      setEditProductTitle("");
                      setEditProductPrice("");
                      setEditProductSizes([]);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Card.Title>{product.title}</Card.Title>
                  <Card.Text>Id: {product.id}</Card.Text>
                  <Card.Text>Price: £{product.price}</Card.Text>
                  <Card.Text>
                    Sizes:
                    <Form.Control as="select" style={{ width: "fit-content" }}>
                      {Array.isArray(product.sizes) &&
                        product.sizes.map((size, index) => (
                          <option key={index} value={size}>
                            {size}
                          </option>
                        ))}
                    </Form.Control>
                  </Card.Text>
                  <Button variant="primary" onClick={() => handleEditProduct(product.id)}>
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      if (window.confirm("Are you sure you want to remove this product?")) {
                        handleRemoveProduct(product.id);
                      }
                    }}
                  >
                    Remove
                  </Button>
                </>
              )}
            </Card.Body>
          </Card>
        ))}
      </div>
      <Button variant="success" style={{ width: "100%", fontSize: "30px" }} onClick={() => setAddProduct(true)}>
        Add Product
      </Button>
      <p>&emsp;</p>
      <p>&emsp;</p>
      {isLoading && <span className="loader"></span>}
      {isLoading && <span className="overlay"></span>}
    </Container>
  );
}

export default Admin;
