import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Success() {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();

        setTimeout(() => {
            navigate('/');
        }, 3000);
        });

    return <div>
        <h1>Thank you for your purchase!</h1>
        <p>You will be redirected to the home page in a moment.</p>
    </div> 
}

export default Success;
