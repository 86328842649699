import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Cancel() {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();

        setTimeout(() => {
            navigate('/');
        }, 3000);
        });

    return (
        <div>
            <h1>Sorry to see you cancelled your purchase</h1>
            <p>You will be redirected to the home page in a moment.</p>
        </div>
    );
}

export default Cancel;
