import { useEffect, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { Row, Col } from "react-bootstrap";
import ProductCard from "../components/ProductCard";
import NavbarComponent from "../components/Navbar";
import { useState } from "react";
import { useRef } from "react";
import { Alert } from "react-bootstrap";

function Store() {
    const [productsArray, setProductsArray] = useState([]);
    const [error, setError] = useState("");
    const fixedChildRef = useRef(null);
    const containerRef = useRef(null);
    const [headerText, setHeaderText] = useState("");

    const updateFixedChildWidth = () => {
        if (containerRef.current && fixedChildRef.current) {
            fixedChildRef.current.style.width = `${containerRef.current.offsetWidth}px`;
        }
    };

    const fetchProducts = useCallback(async () => {
        try {
            const response = await fetch("https://shop.gallowayhillbillies.org/api/load-items");
            const data = await response.json();

            const formattedData = data.map((product) => ({
                ...product,
                sizes: JSON.parse(product.sizes)
            }));

            const productsArray = formattedData;
            setProductsArray(productsArray);
        } catch (error) {
            console.error("Error fetching products:", error);
            setError("Error fetching products");
            setTimeout(() => {
                updateFixedChildWidth();
            }, 1);
        }
    }, []);

    const fetchHeaderText = async () => {
        try {
            const response = await fetch("https://shop.gallowayhillbillies.org/api/get-header-text");
            const data = await response.json();
            console.log(data);
            setHeaderText(data.header_text);
        } catch (error) {
            console.error("Error fetching header text:", error);
        }
    }
        

    useEffect(() => {
        fetchHeaderText();
        fetchProducts();

        if (localStorage.getItem("sessionIdTime") !== null) {
            const currentTime = new Date().getTime();
            const oldTime = localStorage.getItem("sessionIdTime");
            if (currentTime - oldTime > 1000 * 60 * 60 * 24) {
                localStorage.setItem("sessionId", uuidv4());
                localStorage.setItem("sessionIdTime", new Date().getTime());
            }
        }
        if (localStorage.getItem("sessionId") === null) {
            localStorage.setItem("sessionId", uuidv4());
            localStorage.setItem("sessionIdTime", new Date().getTime());
        }
        console.log(localStorage.getItem("sessionId"));
        console.log(localStorage.getItem("sessionIdTime"));
    }, []);

    return (
        <div ref={containerRef}>
            {error && (
                <Alert
                    ref={fixedChildRef}
                    variant="danger"
                    onClose={() => setError(false)}
                    style={{ width: "25rem", textAlign: "center" }}
                    dismissible
                >
                    <Alert.Heading>Error</Alert.Heading>
                    <p dangerouslySetInnerHTML={{ __html: error }} />
                </Alert>
            )}
            <NavbarComponent />
            <h2 align="center" dangerouslySetInnerHTML={{ __html: headerText }}></h2>
            <Row xs={1} md={3} className="g-4">
                {productsArray.map((product, idx) => (
                    <Col align="center" key={idx}>
                        <ProductCard product={product} />
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default Store;